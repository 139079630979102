import HeaderContext from 'context/headerContext'
import useDebounce from 'hooks/useDebounce'
import { useContext, useEffect, useMemo, useState } from 'react'

import { format } from 'date-fns'

import {
  bigNumberFormatter,
  formatToCLP,
  smallNumberFormatter
} from 'utils/formatters/currencyFormatters'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import { Checkbox } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  AccountsRequest,
  AssetRequest,
  AssetSearchRequest,
  ClientsRequest,
  DownloadHoldingsRequest,
  GetInstitutionsRequest,
  HoldingsRequest
} from 'axios/requests/operations'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import { errorNotification } from 'utils/UI/Notifications/Notifications'
import DefaultTable from 'utils/UI/Tables/DefaultTable'

const useStyles = makeStyles((theme) => ({
  holdingsSelectorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  assetTicker: {
    fontFamily: 'InconsolataSemiBold',
    lineHeight: 0.8
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginLeft: 0
    }
  }
}))

export default function HoldingsTable() {
  const classes = useStyles()
  const theme = useTheme()
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency } = headerState
  const [rowsData, setRowsData] = useState([])
  const [clients, setClients] = useState([])
  const [selectedClients, setSelectedClients] = useState([])
  const [accounts, setAccounts] = useState([])
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [institutions, setInstitutions] = useState([])
  const [selectedInstitutions, setSelectedInstitutions] = useState([])
  const [search, setSearch] = useState('')
  const [loadingAssetSearch, setLoadingAssetSearch] = useState(false)
  const [assetOpen, setAssetOpen] = useState(false)
  const [optionsTickers, setOptionsTickers] = useState([])
  const [tickers, setTickers] = useState([])
  const [assetsNamesDict, setAssetsNamesDict] = useState({})
  const debouncedSearch = useDebounce(search, 500)
  const [loadingDownloadHoldings, setLoadingDownloadHoldings] = useState(false)
  const [loadingHoldingsData, setLoadingHoldingsData] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)

  const getColsData = (selectAllChecked, handleSelectAllCheckbox) => [
    {
      id: 'checkbox',
      label: (
        <Checkbox
          color="primary"
          size="small"
          style={{ padding: 0 }}
          indeterminate={
            rowsData && rowsData.some((row) => row.checked) && !selectAllChecked
          }
          checked={selectAllChecked}
          onChange={handleSelectAllCheckbox}
        />
      ),
      align: 'left',
      sortable: false
    },
    {
      id: 'account',
      label: 'Cuenta',
      minWidth: 180,
      ellipsis: true,
      tooltipValue: (row) => row.account
    },
    {
      id: 'currency',
      label: 'Moneda',
      align: 'center'
    },
    {
      id: 'asset',
      label: 'Asset',
      ellipsis: true,
      minWidth: 180,
      tooltipValue: (row) => `${row.asset} (${row.ticker})`,
      render: (row) => (
        <>
          <div className={classes.assetTicker}>{row.ticker}</div>
          <div
            style={{
              marginTop: 5,
              color: theme.palette.grey[500],
              fontSize: '0.7rem',
              lineHeight: 0.8
            }}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block'
              }}
            >
              {row.asset}
            </span>
          </div>
        </>
      )
    },
    {
      id: 'data_source',
      label: 'Fuente',
      minWidth: 50
    },
    {
      id: 'date',
      minWidth: 80,
      align: 'center',
      label: 'Fecha'
    },
    {
      id: 'quantity',
      label: 'Cant.',
      align: 'right',
      minWidth: 120,
      tooltipValue: (row) => `${row.quantity.toLocaleString()}`,
      format: (value) =>
        value.toLocaleString('de-DE', { maximumFractionDigits: 2 })
    },
    {
      id: 'price_local',
      label: 'Precio CLP',
      align: 'right',
      minWidth: 110,
      tooltipValue: (row) => smallNumberFormatter(row.price_local, 2, 4),
      format: (value) => smallNumberFormatter(value)
    },
    {
      id: 'price_usd',
      label: 'Precio USD',
      align: 'right',
      minWidth: 110,
      tooltipValue: (row) => smallNumberFormatter(row.price_usd, 2, 4),
      format: (value) => smallNumberFormatter(value)
    },
    {
      id: 'value_local',
      label: 'Valor CLP',
      align: 'right',
      minWidth: 100,
      bold: true,
      format: (value) => formatToCLP(value)
    },
    {
      id: 'value_usd',
      label: 'Valor USD',
      align: 'right',
      minWidth: 100,
      bold: true,
      format: (value) => bigNumberFormatter(value)
    }
  ]

  const handleChangeCheckbox = (id, checked) => {
    setRowsData((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, checked } : row))
    )
  }

  const handleSelectAllCheckbox = (event) => {
    const newCheckedStatus = event.target.checked
    setSelectAllChecked(newCheckedStatus)
    setRowsData((prevRows) =>
      prevRows.map((row) => ({ ...row, checked: newCheckedStatus }))
    )
  }

  const manageClientSelection = (clients) => {
    setSelectedClients(clients)
    const clientCodes = clients.map((client) => client.code)
    setSelectedAccounts((prevAccounts) =>
      prevAccounts.filter((account) =>
        clientCodes.includes(account.code.split('-')[0])
      )
    )
  }

  useEffect(() => {
    if ((selectedClients.length > 0 && currency) || tickers.length > 0) {
      let didCancel = false
      setLoadingHoldingsData(true)

      const fetchData = async () => {
        try {
          const result = await HoldingsRequest({
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            clients: selectedClients.map((client) => client.id).join(','),
            tickers: tickers.join(','),
            accounts: selectedAccounts.map((account) => account.id).join(','),
            institutions: selectedInstitutions
              .map((institution) => institution.id)
              .join(',')
          })
          if (!didCancel) {
            const parsedData = result.data.holdings.map((item) => ({
              id: item.id,
              account: item.account,
              currency: item.currency,
              asset: item.asset,
              ticker: item.ticker,
              data_source: item.data_source,
              date: item.date,
              quantity: Number(item.quantity.toFixed(4)),
              price_local: item.price_local,
              price_usd: item.price_usd,
              value_local: item.value_local,
              value_usd: item.value_usd,
              checked: item.checked,
              institution_code: item.institution_code
            }))
            setRowsData(parsedData)
            setLoadingHoldingsData(false)
          }
        } catch (err) {
          errorNotification('generalError')
        }
      }

      fetchData()

      return () => {
        didCancel = true
      }
    } else {
      setRowsData([])
    }
  }, [
    startDate,
    endDate,
    currency,
    selectedClients,
    selectedAccounts,
    tickers,
    selectedInstitutions
  ])

  const updatedRowsData = useMemo(() => {
    if (rowsData) {
      return rowsData.map((item) => ({
        checkbox: (
          <Checkbox
            color="primary"
            style={{ padding: 0 }}
            size="small"
            checked={item.checked || false}
            onChange={(e) => handleChangeCheckbox(item.id, e.target.checked)}
          />
        ),
        id: item.id,
        account: item.account,
        currency: item.currency,
        asset: item.asset,
        ticker: item.ticker,
        data_source: item.data_source,
        date: item.date,
        quantity: item.quantity,
        price_local: item.price_local,
        price_usd: item.price_usd,
        value_local: item.value_local,
        value_usd: item.value_usd,
        institution_code: item.institution_code
      }))
    }
    return []
  }, [rowsData])

  useEffect(() => {
    let didCancel = false
    const fetchClients = async () => {
      try {
        const result = await ClientsRequest()
        if (!didCancel) {
          setClients(result.data)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchClients()

    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let didCancel = false
    const fetchInstitutions = async () => {
      try {
        const response = await GetInstitutionsRequest()
        if (!didCancel) {
          setInstitutions(response.data)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchInstitutions()

    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let didCancel = false
    const fetchAccounts = async () => {
      try {
        if (selectedClients.length > 0) {
          const result = await AccountsRequest({
            clients: selectedClients.map((client) => client.id).join(',')
          })
          if (!didCancel) {
            setAccounts(result.data)
          }
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchAccounts()

    return () => {
      didCancel = true
    }
  }, [selectedClients])

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        await AssetRequest()
      } catch (err) {
        errorNotification('generalError')
      }
    }
    fetchAssets().then((result) => {
      if (result) {
        const assetsDict = result.reduce((acc, asset) => {
          acc[asset.id] = asset.name
          return acc
        }, {})
        setAssetsNamesDict(assetsDict)
      }
    })

    return () => {}
  }, [])

  useEffect(() => {
    const assetSearchTickers = async () => {
      try {
        const result = await AssetSearchRequest({
          search: debouncedSearch,
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          clients: selectedClients.map((client) => client.id).join(','),
          accounts: selectedAccounts.map((account) => account.id).join(',')
        })
        return result.data
      } catch (err) {
        errorNotification('generalError')
      }
    }

    if (search.length > 0) {
      setLoadingAssetSearch(true)
      assetSearchTickers().then((tickersFounded) => {
        setOptionsTickers(tickersFounded)
        setLoadingAssetSearch(false)
      })
    } else {
      setLoadingAssetSearch(false)
      setOptionsTickers([])
    }
  }, [debouncedSearch])

  const handleAssetChange = (e) => {
    setSearch(e.target.value)
  }

  const optionsTickersDict = useMemo(() => {
    return optionsTickers.reduce((acc, ticker) => {
      acc[ticker.ticker] = ticker.name
      return acc
    }, {})
  }, [optionsTickers])

  const optionsTickersIds = useMemo(() => {
    return [...tickers, ...optionsTickers.map((ticker) => ticker.ticker)]
  }, [tickers, optionsTickers])

  const downloadHoldingsHandler = async () => {
    setLoadingDownloadHoldings(true)
    try {
      const checkedRowsIds = rowsData
        .filter((row) => row.checked)
        .map((row) => row.id)
      const response = await DownloadHoldingsRequest({
        holding_ids: checkedRowsIds.join(',')
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Operations Holdings.xlsx')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingDownloadHoldings(false)
  }

  return (
    <CardWrapper title="Holdings" titleFeedback="Holdings">
      <Grid
        container
        className={classes.holdingsSelectorContainer}
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            label="Selecciona clientes:"
            variant="outlined"
            placeholder="Clientes"
            margin="normal"
            multiple
            limitTags={2}
            options={clients}
            getOptionLabel={(client) =>
              `[${client.code}] ${client.first_name} ${client.last_name}`
            }
            value={selectedClients}
            onChange={(event, newValues) => {
              manageClientSelection(newValues)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecciona clientes:"
                variant="outlined"
                placeholder="Clientes"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((client, index) => (
                <Tooltip title={client.code} key={index}>
                  <Chip
                    style={{
                      marginRight: 12,
                      backgroundColor: theme.palette.orange.lighten1,
                      color: theme.palette.getContrastText(
                        theme.palette.orange.lighten1
                      )
                    }}
                    size="small"
                    label={client.code}
                    key={client.code}
                    classes={{ deleteIcon: classes.deleteIcon }}
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              ))
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            label="Selecciona cuentas:"
            variant="outlined"
            placeholder="Cuentas"
            margin="normal"
            multiple
            limitTags={2}
            options={accounts}
            getOptionLabel={(account) => `[${account.code}] ${account.name}`}
            value={selectedAccounts}
            onChange={(event, newValues) => {
              setSelectedAccounts(newValues)
            }}
            getOptionSelected={(option, value) => value.id === option.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecciona cuentas:"
                variant="outlined"
                placeholder="Cuentas"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((account, index) => (
                <Tooltip title={account.code} key={index}>
                  <Chip
                    style={{
                      marginRight: 12,
                      backgroundColor: theme.palette.orange.lighten1,
                      color: theme.palette.getContrastText(
                        theme.palette.orange.lighten1
                      )
                    }}
                    size="small"
                    label={account.code}
                    key={account.code}
                    classes={{ deleteIcon: classes.deleteIcon }}
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              ))
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            label="Selecciona uno o más activos:"
            variant="outlined"
            placeholder="Activos"
            margin="normal"
            open={assetOpen}
            onOpen={() => {
              setAssetOpen(true)
            }}
            onClose={() => {
              setAssetOpen(false)
            }}
            multiple
            limitTags={2}
            getOptionLabel={(option) => {
              return `${optionsTickersDict[option]} (${option})`
            }}
            filterSelectedOptions
            getOptionSelected={(option, value) => value === option}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Tooltip title={assetsNamesDict[option]} key={index}>
                  <Chip
                    style={{
                      marginRight: 12,
                      backgroundColor: theme.palette.orange.lighten1,
                      color: theme.palette.getContrastText(
                        theme.palette.orange.lighten1
                      )
                    }}
                    size="small"
                    label={option}
                    key={option}
                    classes={{ deleteIcon: classes.deleteIcon }}
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              ))
            }}
            loadingText={
              loadingAssetSearch
                ? 'Buscando...'
                : 'No se encontraron resultados'
            }
            onBlur={() => setOptionsTickers([])}
            options={loadingAssetSearch ? [] : optionsTickersIds}
            onInputChange={(e) => handleAssetChange(e)}
            value={tickers}
            onChange={(_, _tickers) => {
              setTickers(_tickers)
            }}
            className={classes.assetsSelector}
            noOptionsText="No se encontraron resultados"
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Selecciona uno o más activos:"
                  variant="outlined"
                  placeholder="Activos"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            label="Selecciona instituciones:"
            variant="outlined"
            placeholder="Instituciones"
            margin="normal"
            multiple
            limitTags={2}
            options={institutions}
            getOptionLabel={(institution) =>
              `[${institution.code}] ${institution.name}`
            }
            value={selectedInstitutions}
            onChange={(event, newValues) => {
              setSelectedInstitutions(newValues)
            }}
            getOptionSelected={(option, value) => value.id === option.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecciona instituciones:"
                variant="outlined"
                placeholder="Instituciones"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((institution, index) => (
                <Tooltip title={institution.code} key={index}>
                  <Chip
                    style={{
                      marginRight: 12,
                      backgroundColor: theme.palette.orange.lighten1,
                      color: theme.palette.getContrastText(
                        theme.palette.orange.lighten1
                      )
                    }}
                    size="small"
                    label={institution.code}
                    key={institution.code}
                    classes={{ deleteIcon: classes.deleteIcon }}
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              ))
            }
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Tooltip title={rowsData && rowsData.length ? 'Descargar' : ''}>
            {loadingDownloadHoldings ? (
              <CircularProgress />
            ) : (
              <IconButton
                disabled={!rowsData || !rowsData.length}
                onClick={downloadHoldingsHandler}
                color="primary"
              >
                <GetAppIcon />
              </IconButton>
            )}
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center" item xs={12}>
        {loadingHoldingsData ? (
          <Loading />
        ) : rowsData.length ? (
          <DefaultTable
            colsData={getColsData(selectAllChecked, handleSelectAllCheckbox)}
            rowsData={updatedRowsData}
            maxHeight={false}
          />
        ) : (
          <p>Selecciona clientes o activos para mostrar holdings</p>
        )}
      </Grid>
    </CardWrapper>
  )
}
